<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import Pusher from 'pusher-js'
import CONST from '@/utils/Constants'

export default {
  name: "LargeScreenLayout",
  components :{},

  data() {
    return {
      pusher: null,
      channel: null
    };
  },

  methods: {
    initializePusher() {
      if(!this.pusher) {
        this.pusher = new Pusher(CONST.ENV.VARS.VUE_APP_PUSHER_KEY, {
          cluster: 'eu'
        });

        Pusher.logToConsole = true;
        
        this.channel = this.pusher.subscribe(CONST.PUSHER.CHANNEL);
        this.channel.bind(CONST.EVENT.BACKGROUND.SELECTED, function(data) {
          document.body.style.backgroundImage = 'url(/static/'+data.message+')';
        });
      }
    }
  },

  mounted() {
    this.initializePusher();
  }
}
</script>

<style>

body {
  background-image: url('/static/img/share-email-2-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

</style>